<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.w {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 50px;
		box-sizing: border-box;
	}

	.active {
		color: #0090f0;
	}

	.navbar {
		display: flex;
		align-items: center;
		width: 100%;
		height: 60px;
		font-size: 14px;

		.nav_item {
			cursor: pointer;
		}

		span {
			margin: 0 10px;
		}

		.now {
			color: #0090f0;
		}
	}

	.card {
		width: 100%;
		// height: 335px;
		background-color: #fff;
		padding: 35px;
		box-sizing: border-box;
		margin-bottom: 35px;

		.card_top {
			display: flex;
			align-items: center;

			>div:nth-child(1) {
				width: 66px;
				height: 26px;
				background: url("../../assets/img/course/img3.png") no-repeat;
				background-size: 100%, 100%;
				color: #fff;
				font-size: 14px;
				line-height: 26px;
				padding-left: 8px;
				box-sizing: border-box;
				margin-right: 10px;
			}

			>div:nth-child(2) {
				font-size: 26px;
			}
		}

		.card_money {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 58px;
			font-size: 14px;
			color: #666;
			background-color: rgba(204, 204, 204, 0.22);
			padding: 0 60px;
			box-sizing: border-box;
			margin: 30px 0;

			.mon_left {
				display: flex;
				align-items: flex-end;

				>div:nth-child(1) {
					font-size: 26px;
					font-weight: 700;
					color: #f13232;
					margin-right: 10px;

					span {
						font-size: 16px;
					}
				}

				>div:nth-child(2) {
					margin-bottom: 4px;
				}
			}

			.mon_right {
				img {
					width: 16px;
					height: 18px;
					margin-right: 5px;
					vertical-align: top;
				}
			}
		}

		.card_time {
			font-size: 14px;
			color: #999;
			margin-left: 70px;

			>div {
				margin-bottom: 20px;
			}

			img {
				width: 18px;
				height: 18px;
				vertical-align: bottom;
				margin-right: 5px;
			}

			.btm {
				display: flex;

				span {
					margin: 0 30px;
				}
			}

			.card_btn {
				width: 160px;
				height: 50px;
				background: #f13232;
				border-radius: 6px;
				text-align: center;
				line-height: 50px;
				color: #fff;
				font-size: 20px;
				cursor: pointer;
			}
		}
	}

	.card_body {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.body_left {
			width: 870px;
			background-color: #fff;
		}

		.body_right {
			width: 300px;
			height: 500px;
			background-color: #fff;

			.course_top {
				width: 100%;
				height: 66px;
				line-height: 66px;
				border-bottom: 1px solid #f2f2f2;
				font-size: 20px;
				font-weight: 700;
				padding: 0 20px;
				box-sizing: border-box;

				img {
					width: 24px;
					height: 18px;
					margin-right: 10px;
					vertical-align: middle;
				}
			}

			.course_body {
				padding: 20px;
				box-sizing: border-box;

				.course_item {
					display: flex;
					margin-bottom: 22px;
					cursor: pointer;

					.img {
						width: 130px;
						height: 78px;
						border-radius: 6px;
					}

					.rt {
						width: 44%;
						margin-left: 10px;

						>div:nth-child(1) {
							font-size: 14px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
						}

						>div:nth-child(2) {
							font-size: 12px;
							color: #999;
							margin-top: 22px;
						}
					}
				}
			}
		}
	}
</style>
<template>
	<div>
		<div class="w">
			<!-- 面包屑 -->
			<div class="navbar">
				<div>当前位置：</div>
				<div class="nav_item" v-for="(item, index) in navList" :key="index" @click="jump(index)">
					{{ item }}
					<span>&gt;</span>
				</div>
				<div class="now">
					{{ course_info.alias_name || series_info.alias_name }}
				</div>
			</div>
			<!-- 课程信息 -->
			<div class="card">
				<div class="card_top">
					<div>{{ active2 == 1 ? "精品课" : "系列课" }}</div>
					<div>{{ course_info.alias_name || series_info.alias_name }}</div>
				</div>
				<div class="card_money">
					<div class="mon_left">
						<div>
							<span>￥</span>
							{{ course_info.alias_price || series_info.alias_price }}
						</div>
						<!-- <div>单买总价：<del> ￥5200</del></div> -->
					</div>
					<div class="mon_right">
						<!-- <img src="../../assets/img/course/icon_1_12.png" alt />
            分享 -->
					</div>
				</div>
				<div class="card_time">
					<div>
						<img v-if="active2 == 1" src="../../assets/img/course/icon_2_1.png" alt />
						{{ active2 == 1 ? " 开播时间：" : "" }}{{ course_info.create_at }}
					</div>
					<div class="btm">
						<div>
							<img src="../../assets/img/course/icon_1_10.png" alt />
							学习人数：{{ course_info.alias_purchase_num || series_info.alias_purchase_num }}
						</div>
						<span>|</span>
						<div>
							<img src="../../assets/img/course/icon_1_11.png" alt />
							课时数量：{{ course_info.count || series_info.count }}
						</div>
					</div>
					<div class="card_btn" @click="goVedio" v-if="active2 == 1 && course_info.is_buy == 1 && user_id">
						开始学习
					</div>
					<!-- <div class="card_btn" v-if="active2 == 0 && series_info.is_buy == 1 && user_id">
						已购买
					</div> -->
					<div class="card_btn" @click="goOrder" v-if="active2 == 0 && series_info.is_buy == 0 && user_id">
						购买课程
					</div>
					<div class="card_btn" @click="goOrder" v-if="course_info.is_buy == 0 && user_id">
						购买课程
					</div>
					<div class="card_btn" @click="goLogin" v-if="!user_id">请登录</div>
					<!-- <div class="card_btn" @click="goOrder" v-if="user_id && active2 == 0">
						购买课程
					</div> -->
					<!-- <div class="card_btn" @click="goOrder">购买课程</div> -->
				</div>
			</div>
			<div class="card_body">
				<div class="body_left">
					<Taocan v-if="active2 == 0" :taocanVal="taocan_list" :isbuy="series_info.is_buy" @changDetail="changDetail"></Taocan>
					<Mulu v-if="active2 == 1" :courseInfo="course_info" :muluVal="mulu_list"></Mulu>
				</div>
				<!-- 课程推荐 -->
				<div class="body_right">
					<div class="course_top">
						<img src="../../assets/img/course/icon_1_13.png" alt />
						课程推荐
					</div>
					<div class="course_body">
						<div class="course_item" v-for="(item, index) in courseList" :key="index"
							@mouseenter="active = index" @mouseleave="active = -1"
							@click="changeDetail(item.culum_id,item.type)">
							<vue-hover-mask>
								<!-- 默认插槽 -->
								<img class="img" :src="item.alias_litpic" alt />
								<!-- action插槽 -->
								<template v-slot:action>
									<i class="icon-bianji-copy el-icon-video-play"></i>
								</template>
							</vue-hover-mask>
							<div class="rt">
								<div :class="{ active: active == index }">
									{{ item.alias_name }}
								</div>
								<div>已有{{ item.alias_purchase_num }}人学习</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Taocan from "@/views/live/detailChild/taocan.vue";
	import Mulu from "@/views/live/detailChild/mulu.vue";
	import VueHoverMask from "@/components/VueHoverMask";
	import {
		getCourseDetail,
		series,
		recommend
	} from "../../assets/js/course";
	export default {
		components: {
			Taocan,
			Mulu,
			VueHoverMask,
		},
		data() {
			return {
				user_id: JSON.parse(window.localStorage.getItem("userInfo")) ?
					JSON.parse(window.localStorage.getItem("userInfo")).id : "",
				active: -1,
				active2: 0,
				navList: ["首页", "课程"],
				course_info: {},
				mulu_list: [],
				series_info: "",
				taocan_list: [],
				courseList: [],
			};
		},
		watch: {
			data(val, newval) {
				console.log(val)
				console.log(newval)
			}
		},
		created() {
		},
		mounted() {
			if (!this.$route.query.id) {
				this.$router.push("/index");
				return;
			}
			// active2为1 是精品课  为2是系列课
			// console.log(this.$route.query.active2);
			if (this.$route.query.active2 == 2) {
				this.active2 = 0;
			} else {
				this.active2 = this.$route.query.active2;
			}
			this.culum_id = this.$route.query.id;
			if (this.active2 == 0) {
				this.seriesData();
			} else {
				this.getCourseDetail();
			}
			this.getrecommend();
		},
		methods: {
			// 获取课程推荐
			getrecommend() {
				recommend({
					culum_id:this.culum_id,
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.courseList = res.data;
					}
				});
			},
			// 切换课程详情
			changeDetail(id, type) {
				this.culum_id = id;
				if (type == 1) {
					this.active2 = 1
					this.getCourseDetail()
				} else {
					this.active2 = 0
					this.seriesData()
				}
			},
			goLogin() {
				this.$router.push({
					path: "/login",
					query: {
						active: 0,
					},
				});
			},
			goVedio() {
				this.$router.push({
					path: "/video",
					query: {
						culum_id: this.course_info.culum_id,
						video_id: this.mulu_list[0].section[0].id,
					},
				});
			},
			goOrder() {
				if (this.active2 == 1) {
					this.$router.push({
						path: "/establish",
						query: {
							culum_id: this.course_info.culum_id,
							type: 1
						},
					});
				} else {
					this.$router.push({
						path: "/establish",
						query: {
							culum_id: this.series_info.id,
							type: 2
						},
					});
				}
			},
			jump(i) {
				if (i == 0) {
					this.$router.push({
						path: "/index"
					});
				} else if (i == 1) {
					if (this.active2 == 1) {
						this.$router.push({
							path: "/course",
							query: {
								type: 1
							}
						});
					} else {
						this.$router.push({
							path: "/course",
							query: {
								type: 2
							}
						});
					}

				}
			},
			changDetail(e) {
				(this.active2 = 1), (this.culum_id = e);
				this.getCourseDetail();
			},
			// 获取课程详情
			getCourseDetail() {
				getCourseDetail({
					user_id: this.user_id,
					siteId: window.localStorage.getItem("siteId"),
					culum_id: this.culum_id,
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.course_info = res.data.info;
						this.mulu_list = res.data.section;
						// console.log(this.mulu_list);
					}
				});
			},
			// 系列课详情
			seriesData() {
				series({
					user_id: this.user_id,
					siteId: window.localStorage.getItem("siteId"),
					id: this.culum_id,
				}).then((res) => {
					// console.log(res);
					// console.log(res.data);
					this.series_info = res.data.series;
					this.taocan_list = res.data.culum;
				});
			},
		},
	};
</script>
